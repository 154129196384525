import $ from 'jquery';
import 'popper.js';
import 'bootstrap';

import lazySizes from 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg'; // Lightweight polyfill for <picture>
import { init as initApm } from '@elastic/apm-rum'

import CookiesOptions from './components/cookies-options';
import Navbar from './components/navbar';
import Map from './components/map';
import WhatsappChathead from './components/whatsapp-chathead';
import EventDispatcher from './components/event-dispatcher';
import { isConfiguratorMode } from './utils/configurator';
import { importSVG } from '../../shared/utils';
import { initializePopovers } from '../../shared/utils';
// import * as Sentry from "@sentry/browser";

const CLOSE_MENU = 'Închide Meniu';
const MENU = 'Meniu';

//
// Initializations
//

function initializeMenu() {
  $("#menu-toggler").click(function(){
    $("body").toggleClass("no-scroll");
    $("#menu-toggler").closest(".navbar").toggleClass("navbar-expanded");
    $(".navbar-collapse").toggleClass("d-flex flex-column");
    $("#menu-toggler").toggleClass("close-menu");
    $("#menu-toggler").toggleClass("menu-color");
    $("#menu-toggler span").html($("#menu-toggler span").html() == CLOSE_MENU ? MENU : CLOSE_MENU);
  });
}

/**
 * Initialize navbar
 */
function initializeHeaderBehaviour() {
  var $navbar = $(".navbar");
  if ($navbar.length > 0) {
    new Navbar($navbar.get(0));
  }
}

function initializeFullscreenBehaviour() {
  $('.fullscreen-overlay-trigger').on('click', function(event) {
    var $trigger = $(this),
        $target = $( $trigger.data('target') ),
        $body = $('html');

    // Prevent body scroll when overlays are shown
    $body.addClass('overflow-hidden')
         .addClass('backdrop');

    // Re-enable on target (collapse container) hide
    $target.one('hidden.bs.collapse', function(event) {
      $body.removeClass('overflow-hidden')
           .removeClass('backdrop');
    });
  });
}

function initializeCustomFileInputs() {
  // Save original label content, in case the user selects no file
  $('.custom-file input').each(function(index, el) {
    var $innerLabel;

    $innerLabel = $(el).next('.custom-file-label').find('.custom-file-label-inner');

    $(el).data('original-content', $innerLabel.html());
  });

  // Display file names on change
  $('.custom-file input').change(function (e) {
    var files = [],
        labelContent,
        $innerLabel;

    $innerLabel = $(this).next('.custom-file-label')
                         .find('.custom-file-label-inner');

    for (var i = 0; i < $(this)[0].files.length; i++) {
        files.push($(this)[0].files[i].name);
    }

    labelContent = files.join(', ');
    if (!labelContent) {
      labelContent = $(this).data('original-content');
      $innerLabel.removeClass('selected');
    } else {
      $innerLabel.addClass('selected');
    }

    $innerLabel.html(labelContent)
  });
}

function initializeMaps() {
  function initializeMap(el) {
    let lat = $(el).data('lat'),
        lng = $(el).data('lng'),
        zoom = $(el).data('zoom'),
        fuzzy = $(el).data('fuzzy');

    let m = new Map(el, lat, lng, zoom, fuzzy);
  }

  // This event is triggered when lazysizes considers that it is time to lazy load an element
  document.addEventListener('lazybeforeunveil', function(e){
    $(e.target).filter('.map').each(function(index, el) {
      initializeMap(el);
    });
  });

  // Initialize maps that are not supposed to be lazy loaded
  $('.map').not('.lazyload').each(function(index, el) {
    initializeMap(el);
  });
}

function initializeChatheads() {
  let $chatheadsWA = $('.chatheads .btn-whatsapp');
  if ($chatheadsWA.length > 0) {
    new WhatsappChathead($chatheadsWA.get(0));
  }
}

function initializeCookies(cookiesContainerSelector, shouldHide) {
  let $cookiesContainer = $(cookiesContainerSelector);
  if ($cookiesContainer.length > 0) {
    new CookiesOptions($cookiesContainer.get(0), shouldHide);
    return true;
  }
}

function initializeEventDispatcher() {
  new EventDispatcher(document);
}

function initializeConfiguratorMode() {
  if (isConfiguratorMode()) {
    var pageData = $('body').data('page-data');

    // Send current active page name to configurator
    var message = {
      type: 'page-change',
      data: pageData,
    }

    window.parent.postMessage(message, window.location.origin);

    // remove WA link if in configurator mode
    $('.btn-whatsapp').click(function(e) {
        e.preventDefault();
    });

    if (pageData && !pageData.editMode) {
      // Keep ?preview=1 parameter when navigating the iframe in configurator
      // preview mode (not in edit mode).
      // Note that we're only doing this for a[href=] links, which should
      // cover almost all usecases.
      $("a").click(function(e) {
        var params = window.location.search,
            dest = $(this).attr('href') + params;

        e.preventDefault();
        window.setTimeout(function() {
          window.location.href = dest;
        }, 100);
      });
    }
  }
}

//
// APM entrypoint
//

if (kairos.config.APM_SERVER_URL) {
  initApm({

    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: 'kairos-sites',

    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: kairos.config.APM_SERVER_URL,

    // Set service version (required for sourcemap feature)
    serviceVersion: ''
  });
}

//
// Sentry entrypoint - currently disabled because it's too spammy
//

// if (kairos.config.SENTRY_DSN) {
//   Sentry.init({
//     dsn: kairos.config.SENTRY_DSN,

//     // Alternatively, use `process.env.npm_package_version` for a dynamic release version
//     // if your build tool supports it.
//     release: kairos.config.GIT_COMMIT,
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       Sentry.replayIntegration(),
//     ],

//     environment: kairos.config.ENVIRONMENT,

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for tracing.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 0.005,

//     // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
//     // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

//     // Capture Replay for 10% of all sessions,
//     // plus for 100% of sessions with an error
//     replaysSessionSampleRate: 0.0,
//     replaysOnErrorSampleRate: 0.1,
//   });
// }


//
// Entrypoint
//

$(function () {
  importSVG(require.context('/assets/site/svg', true, /\.svg$/));
  initializeMenu();
  initializeHeaderBehaviour();
  initializeFullscreenBehaviour();
  initializeCustomFileInputs();
  initializeMaps();
  initializeChatheads();
  initializePopovers($);
  initializeEventDispatcher();
  initializeConfiguratorMode();

  // only show cookies if we're not inside the configurator and if we have the cookies template included
  if (!isConfiguratorMode() && !initializeCookies('.cookies-policy', false)) {
    initializeCookies('.cookies-modal', true);
    // Allow visibility
    $('.cookies-modal').removeClass('d-none');
  }
});
